import React from "react";
import { Ressources } from "../pages/plugin/ressources/ressources";
import { RouteComponentProps, Switch, useRouteMatch } from "react-router-dom";
import Account from "../pages/plugin/account/account";
import PrivateRoute from "./privateRoute";
import EditAccountFactory from "../pages/plugin/account/editAccountFactory";
import PluginAccountLayout from "../layouts/pluginAccountLayout";
import { TableProvider } from "../components/table/customTable/context/tableContext";
import Invoices from "../pages/plugin/account/invoices";
import { Services } from "../pages/plugin/account/services";

interface PluginParams {
	path: "/plugin";
}

export const Plugin = () => {
	let router = useRouteMatch<PluginParams>();

	return (
		<Switch>
			<PrivateRoute
				exact
				path={`${router.path}/ressources`}
				component={Ressources}
				layout={null}
				providers={null}
			/>
			<PrivateRoute
				exact
				path={`${router.path}/account`}
				component={(props: RouteComponentProps) => (
					<PluginAccountLayout {...props}>
						{<Account />}
					</PluginAccountLayout>
				)}
				layout={null}
				providers={null}
			/>
			<PrivateRoute
				exact
				path={`${router.path}/account/edit`}
				component={EditAccountFactory}
				layout={null}
				providers={null}
			/>
			<PrivateRoute
				exact
				path={`${router.path}/account/services`}
				component={(props: RouteComponentProps) => (
					<PluginAccountLayout {...props}>
						{<Services />}
					</PluginAccountLayout>
				)}
				layout={null}
				providers={null}
			/>
			<PrivateRoute
				exact
				path={`${router.path}/account/invoice`}
				component={(props: RouteComponentProps) => (
					<PluginAccountLayout {...props}>
						{<Invoices />}
					</PluginAccountLayout>
				)}
				layout={null}
				providers={[TableProvider]}
			/>
			<PrivateRoute
				exact
				path="/account/edit"
				component={EditAccountFactory}
				layout={null}
				providers={null}
			/>
		</Switch>
	);
};
