import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Style
import './taskButton.scss';

// Icon
import { ReactComponent as IconTask } from '../../../static/icons/icon-task.svg';
import { ReactComponent as IconWatermark } from '../../../static/icons/tasks/watermark.svg';
import { ReactComponent as IconAnomyzation } from '../../../static/icons/tasks/anonymization.svg';
import { useRecoilValue } from 'recoil';
import { TASKS_UNAVAILABLE_TYPES, serviceUsableState } from '../../../recoil/services.atom';
import Modal, { IModal } from '../../modal/Modal';
import CustomButton from '../../button/button';

type TTaskList = {
    url: string;
    classes: string;
    icon?: any;
    title: string;
};

const TaskButton: React.FC = () => {
    const [y, setY] = useState(window.scrollY);
    const [scrollDirection, setScrollDirection] = useState<string>('up'); // scroll-up => default state
    const [showList, setShowList] = useState<boolean>(false);
    const { t } = useTranslation();
    const canUseTask = useRecoilValue(serviceUsableState);
    const [modalState, setModal] = useState<IModal>({
        show: false, showCancel: false,
        onConfirm: () => setModal({ ...modalState, show: false }),
    });
    const { push } = useHistory();

    const handleNavigation = useCallback(
        (e: Event): void => {
            const window = e.currentTarget as Window;

            if (y > window.scrollY) {
                setScrollDirection('up');
            } else if (y < window.scrollY) {
                setScrollDirection('down');
            }

            setY(window.scrollY);
        }, [y]
    );

    const handleModal = () => {
        if (canUseTask.reason === TASKS_UNAVAILABLE_TYPES.INACTIVE_SERVICES) {
            setModal((prev) => ({
                ...prev,
                show: true,
                title: t('error.servicesDeactivated-title'),
                message: t('error.servicesDeactivated-message'),
            }));
        }
        if (canUseTask.reason === TASKS_UNAVAILABLE_TYPES.CARD_EXPIRED) {
            setModal((prev) => ({
                ...prev,
                show: true,
                title: t('error.cardExpired-title'),
                message: t('error.cardExpired-message'),
            }));
        }
    };

    const handleButtonService = (goTo: string) => {
        if (!canUseTask.status) {
            setShowList(false)
            handleModal();
        } else {
            setShowList(false)
            push(goTo);
        }
    };

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener('scroll', handleNavigation, true);

        return () => {
            window.removeEventListener('scroll', handleNavigation, true);
        }
    }, [handleNavigation]);

    const showTaskList = () => {
        setShowList(!showList);
    };

    const taskList: TTaskList[] = [
        {
            url: '/task-manager/anonymization',
            classes: 'button btn-task white',
            icon: <IconAnomyzation fill="white" />,
            title: t('taskButton.task-list.anonymization')
        },
        {
            url: '/task-manager/watermark',
            classes: 'button btn-task white',
            icon: <IconWatermark fill="white" />,
            title: t('taskButton.task-list.watermark')
        },
    ];

    return (
        <div className="task-actions-container">
            <ul className={`task-list${showList ? ' show' : ''}`}>
                {
                    taskList.map((element, index: number) =>
                        <li key={index}>
                            <div
                                role="button"
                                tabIndex={0}
                                onClick={() => handleButtonService(element.url)}
                                className={element.classes}>
                                <span className="icon">
                                    {element.icon}
                                </span>

                                <span className="button-text">{element.title}</span>
                            </div>
                        </li>
                    )
                }
            </ul>

            <button
                type="button"
                className={`button btn-task scroll-${scrollDirection}${showList ? ' active' : ''}`}
                title="Create a task"
                onClick={showTaskList}
            >
                <div className="icon">
                    <IconTask fill="#F50A6B" />
                </div>
                {
                    showList ? (
                        <span className="button-text">{t('taskButton.select')}</span>
                    ) : (
                        <span className="button-text">{t('taskButton.create')}</span>
                    )
                }

            </button>
            <Modal {...modalState} />

        </div>
    );
};

export default TaskButton;
