import React, { CSSProperties, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import RoundedLoader from "../loader/RoundedLoaderAnimated";
import ProgressBar from "@ramonak/react-progress-bar";
import { Checkbox } from "../checkbox/checkbox";
import useWindowDimensions from "../../hook/useWindowDimensions";

import "./modal.scss";
export interface IModal {
	show?: boolean;
	title?: string;
	element?: any;
	elementPositionRight?: boolean;
	elementPositionMiddle?: boolean;
	message?: string;
	messagePartOne?: string;
	messagePartTwo?: string;
	customClass?: string;
	confirmBtnCssClass?: string;
	cancelBtnCssClass?: string;
	onConfirm?: any;
	onCancel?: any;
	confirmBtnText?: string;
	customButtons?: any;
	showCancel?: boolean;
	br?: any;
	cancelBtnText?: string;
	progress?: number | null;
	loading?: boolean;
	withCheckBox?: boolean;
	checkboxValue?: any;
	checkboxLabelStyle?: CSSProperties;
	input?: boolean;
}

const Modal: React.FC<IModal> = ({
	show = false,
	title = "",
	element,
	elementPositionRight = false,
	elementPositionMiddle = false,
	message,
	messagePartOne,
	messagePartTwo,
	customClass,
	confirmBtnCssClass = "btn-main",
	cancelBtnCssClass,
	onConfirm = () => void 0,
	onCancel = () => void 0,
	confirmBtnText,
	customButtons,
	showCancel,
	br,
	cancelBtnText = "Cancel",
	progress,
	loading = false,
	withCheckBox,
	checkboxLabelStyle,
	checkboxValue,
}) => {
	const [checkbox, setCheckbox] = useState(false);
	const { width } = useWindowDimensions();

	return (
		//@ts-ignore
		<SweetAlert
			show={show}
			title={title}
			onCancel={onCancel}
			onConfirm={() => onConfirm(checkbox)}
			closeOnClickOutside={false}
			customButtons={customButtons}
			customClass={"modal " + customClass}
			confirmBtnCssClass={confirmBtnCssClass}
			confirmBtnText={confirmBtnText}
			cancelBtnCssClass={"btn-white-main " + cancelBtnCssClass}
			cancelBtnText={cancelBtnText}
			focusConfirmBtn={false}
			showCancel={showCancel && !loading}
			showConfirm={!loading}
		>
			<p className="modal-message">
				{elementPositionRight === true ? (
					<>
						{message}
						{br === true ? <br /> : null}
						<strong className="primary"> {element}</strong>
					</>
				) : elementPositionMiddle === true ? (
					<>
						{messagePartOne}
						<strong className="primary">{element}</strong>
						<br />
						{messagePartTwo}
					</>
				) : (
					<>
						<strong className="primary">{element} </strong>
						{br === true ? <br /> : null}
						{message}
						<br />
					</>
				)}
			</p>
			{withCheckBox && (
				<Checkbox
					value={checkboxValue as string}
					isChecked={checkbox as boolean}
					onClick={() => setCheckbox(!checkbox)}
					labelText={
						"Notify me per email when processing is completed"
					}
					checkboxLabelStyle={checkboxLabelStyle}
					labelTextStyle={{ left: `${width < 992 ? "0" : "40px"}` }}
					checkMarkStyle={{
						transform: `${
							width < 992 ? "none" : "translateX(170%)"
						}`,
					}}
				/>
			)}
			{loading && <RoundedLoader isLoading={loading} />}
			{progress && (
				<ProgressBar completed={progress || 0} bgColor="#f50a6b" />
			)}
		</SweetAlert>
	);
};

export default Modal;
